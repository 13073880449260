
export default {
    // "baseURL": "http://hy.zzlying.com/gw",
    // "loginURL": "http://hy.zzlying.com/",
    "baseURL": "https://hy.zzlying.com",
    "loginURL": "https://hy.zzlying.com/",
    "sentry": {
        "DSN": "",
        "version": "v1.0.0"
    }
}