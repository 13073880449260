import axios from "@/lib/api.request"
// 会员卡
// 获取用户信息
export const getuserInfo = (params) => {
    return axios({
        url: "/admin-api/system/user/profile/member/get",
        method: "get",
        params: params,
    })
}
// 消费记录
export const consumptionRecord = (params) => {
    return axios({
        url: "/admin-api/card/consumption-record/page/",
        method: "get",
        params: params,
    })
}
// 获取会员卡优惠券
export const cardCoupon = (params) => {
    return axios({
        url: "/admin-api/member/card-coupon/page/",
        method: "get",
        params: params,
    })
}
// 登录认证
export const login = (params) => {
    return axios({
        url: "/admin-api/system/auth/login",
        method: "post",
        data: params,
    })
}
// 修改个人信息
export const userinfoUpdate = (params) => {
    return axios({
        url: "/admin-api/system/user/profile/update",
        method: "put",
        data: params,
    })
}
// 上传用户头像
export const avatarUpdate = (params) => {
    return axios({
        url: "/admin-api/system/user/profile/update-avatar",
        method: "post",
        data: params,
        headers: { 'content-type': 'multipart/form-data' },
    })
}
// 修改密码
export const passwordUpdate = (params) => {
    return axios({
        url: "/admin-api/system/user/profile/update-password",
        method: "put",
        data: params,
    })
}



