import axios from "axios"
import Cookies from "js-cookie"
import config  from "../../config/config.base"
import router from '@/router'

let instance = axios.create({
    headers: {
        'content-type': 'application/json',
        'Authorization': sessionStorage.getItem("Authorization"),
        'tenant-id':1
    },
})

instance.defaults.baseURL = config.baseURL
instance.defaults.withCredentials = true  //跨域访问
// instance.defaults.timeout =  0;

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    config.headers['Authorization'] = sessionStorage.getItem("Authorization")
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    console.log(response)
    if (response.status == 200) {
        if (response.data.code == '401') {
            // sessionStorage.removeItem('Authorization');
            router.push({name:'logon'})
        }
    }
    return Promise.resolve(response.data)
}, function (error) {
    // 对响应错误做点什么
    if(error && error.response && error.response.status == 401) {
        console.error('Error: ', error);  // 打印出错误信息
    }     
    return Promise.reject(error)
})

export default instance